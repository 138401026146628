
import Vue from 'vue';
import Component from 'vue-class-component';
import { RepositoryFactory } from '../repositories/RepositoryFactory';
const AnswerRepository = RepositoryFactory.get('answer');

@Component
export default class QuestionConfirm extends Vue {
  // inquiry_type
  inquiry_type = '';
  // 質問
  questionnaire: any[] = [];
  // 回答一覧
  answers: any = {};
  // ページ番号
  page_num = 1;
  // 回答日
  answer_date = '';
  // 質問紙
  questionnaire_id = '';

  // 選択肢
  choices_type7 = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
  ];

  // 質問を質問群単位で表示するかどうか
  is_every_question_displayed_by_group = false;

  choicesType4(question: any): any[] {
    const choices: any[] = [];
    Object.keys(question.selection).forEach(function(index) {
      choices.push({
        value: index,
        label: question.selection[index],
      });
    });

    console.log(choices);

    return choices;
  }

  choiceTypeMulti(question: any, answer: []): string {
    const multi_answers: string[] = [];
    Object.values(answer).forEach(function(value) {
      multi_answers.push(question.selection[value]);
    });
    return multi_answers.join(', ');
  }

  // 回答完了画面へ
  async next(): Promise<any> {
    this.toggleLoading({ is_loading: true, time: 0 });
    console.log('【confirm answer_date】', this.$store.state.answer_dates.answer_date);

    // プレビュー時は回答を保存しない、添付画像の削除のみ行う
    if (this.isPreview()) {
      AnswerRepository.deleteTmpAnswerImage({
        answers: this.answers,
      }).then();
      this.$router.push('/preview/questionnaire/' + this.$route.params.uuid + '/complete');
      return;
    }

    const response = await AnswerRepository.create({
      answers: this.answers,
      answer_date: this.$store.state.answer_dates.answer_date,
    });

    if (!response) {
      this.toggleLoading({ is_loading: false, time: 1 });
      this.$toasted.error('ネットワークに問題があります', {
        duration: 2000,
        position: 'top-center',
      });
    } else if ('timeout' in response && response.timeout == true) {
      this.$toasted.error('タイムアウトが発生しました', {
        duration: 2000,
        position: 'top-center',
      });
      this.toggleLoading({ is_loading: false, time: 1 });
    } else if (response.status == 200 && response.data.status == 'success') {
      console.log('success');
      this.$store.commit('answer/setAnswers', {});
      this.$router.push('/questionnaire/' + this.questionnaire_id + '/complete');
    } else if (response.status == 400 && response.data.message == 'duplication') {
      this.toggleLoading({ is_loading: false, time: 1 });
      this.$toasted.error('お客様と回答日時の組がすでに存在しています', {
        duration: 2000,
        position: 'top-center',
      });
    } else if (response.status == 401) {
      this.$toasted.error('ログアウトされています', {
        duration: 2000,
        position: 'top-center',
      });
      this.$store.commit('setUser', null);
      this.$store.commit('setRemember', false);
      this.$store.commit('setLimit', 0);
      this.$store.commit('setApiToken', null);
      this.$store.commit('answer/setAnswers', {});
      // TODO:questionnaireの初期化 answer_datesは？
      this.$router.push('/auth/login');
    } else {
      this.toggleLoading({ is_loading: false, time: 1 });
      console.log('failure');
      this.$toasted.error('処理に失敗しました', {
        duration: 2000,
        position: 'top-center',
      });
    }
  }

  // 修正画面へ
  fix(question_group_id: number, question_id: number): void {
    if (this.is_every_question_displayed_by_group) {
      this.$router.push(
        `${
          this.isPreview()
            ? '/preview/questionnaire-by-group/' + this.$route.params.uuid
            : '/questionnaire-by-group/' + this.questionnaire_id
        }` +
          '/' +
          question_group_id +
          '?fix=1' +
          '&' +
          'question_no=' +
          question_id
      );
    } else {
      this.$router.push(
        `${
          this.isPreview()
            ? '/preview/questionnaire/' + this.$route.params.uuid
            : '/questionnaire/' + this.questionnaire_id
        }` +
          '/' +
          question_group_id +
          '/' +
          question_id +
          '?fix=1'
      );
    }
  }

  // Loadingの表示，非表示の制御
  toggleLoading(params: { is_loading: boolean; time: number }): void {
    this.$emit('toggleLoading', params);
  }

  created(): void {
    // .envのVUE_APP_DISPLAY_QUESTIONS_BY_GROUP === '1'の場合、質問群単位で質問表示
    this.is_every_question_displayed_by_group = process.env.VUE_APP_DISPLAY_QUESTIONS_BY_GROUP === '1';
  }

  // プレビューかどうか
  isPreview(): boolean {
    return this.$route.path.includes('preview');
  }

  async mounted(): Promise<any> {
    this.toggleLoading({ is_loading: true, time: 0 });

    this.questionnaire = await this.$store.state.questionnaire.questionnaire;
    this.questionnaire_id = await this.$route.params.questionnaire_id;

    this.answers = this.$store.state.answer.answers;
    console.log('【confirm answer】', this.answers);
    console.log('【confirm questionnaire】', this.questionnaire);

    // ブラウザバックを無効化
    addEventListener('popstate', () => {
      this.$router.push(
        `${
          this.isPreview()
            ? '/preview/questionnaire/' + this.$route.params.uuid
            : '/questionnaire/' + this.questionnaire_id
        }` + '/confirm'
      );
    });

    window.scrollTo(0, 0);
    this.toggleLoading({ is_loading: false, time: 0.5 });
  }
}
